<template>
  <div>
    <!-- {{ permission_view_topics }}
    {{ permission_manage_topics }}
    {{ permission_view_topics_content }}
    {{ permission_manage_topics_content }} -->

    <div v-if="pageLoading">
      <b-card>
        <div class="mt-5 mb-5 d-flex justify-content-center align-items-center">
          <b-spinner label="Loading..." />
        </div>
      </b-card>
    </div>
    <div v-else>
      <b-card v-if="!permission_view_topics && !permission_view_topics_content">
        <div v-html="Helpers.noPermission()"></div>
      </b-card>
      <div v-else>
        <add-new-level :is-add-new-level-sidebar-active.sync="isAddNewLevelSidebarActive"
          :AddSubcategoryid="AddSubcategoryid" @clickaddnewlevel="clickaddnewlevel" />
        <add-edit-level :is-add-edit-level-sidebar-active.sync="isAddEditLevelSidebarActive" :levelidedit="levelidedit"
          :EditLevelname="EditLevelname" :EditNoofquestion="EditNoofquestion" :editLevelStatus="editLevelStatus"
          @clickaddeditlevel="clickaddeditlevel" />
        <b-card no-body>
          <div class="m-2">
            <b-row>
              <b-col cols="12" md="6" class="
                                d-flex
                                align-items-center
                                justify-content-start
                                mb-1 mb-md-0
                            ">
                <b-breadcrumb :items="items" />
              </b-col>
              <b-col cols="12" md="6">
                <div class="
                                    d-flex
                                    align-items-center
                                    justify-content-end
                                ">
                  <b-form-input @input="levelsFilter" v-model="searchQuery" class="d-inline-block mr-1"
                    placeholder="Search by Level no, Question title, Question type" />
                  <b-button variant="primary" @click="addnewlevelinsubcategory()" v-if="permission_manage_topics || permission_manage_topics_content">
                    <span class="text-nowrap">Add Level</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
          <b-overlay :show="show" rounded="sm">
            <b-table-simple hover caption-top responsive class="rounded-bottom mb-0">
              <b-thead head-variant="light">
                <b-tr>
                  <b-th class="text-center">Level No</b-th>
                  <b-th class="text-center">Question Title</b-th>
                  <b-th class="text-center">Question Type</b-th>
                  <b-th class="text-center">No. of question</b-th>
                  <b-th class="text-center">Version</b-th>
                  <b-th class="text-center">Status</b-th>
                  <b-th class="text-center" v-if="permission_manage_topics || permission_manage_topics_content">Action</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <template v-for="(level_data, index) in table_data">
                  <b-tr :key="index + '12'">
                    <b-td class="text-center">
                      <div>
                        {{ level_data.levelName }}
                      </div>
                    </b-td>
                    <b-td @click="
                      savedatlevel(
                        level_data.levelName,
                        level_data.categoryId
                      )
                      ">
                      <b-link class="text-center" :to="{
                        path:
                          '/categorylevels/question/' +
                          level_data._id,
                        query: { categoryId: categoryId, theme: level_data.oldLevelId ? true : false },
                        }">
                            <div v-if="level_data.question && level_data.question.title">
                          {{ level_data.question.title }}
                        </div>
                        <div v-else>N/A</div>
                      </b-link>
                    </b-td>
                    <b-td class="text-center">
                      <div v-if="level_data.question">
                        {{ level_data.question.questionType }}
                      </div>
                      <div v-else>N/A</div>
                    </b-td>
                    <b-td class="text-center">
                      {{ level_data.noOfQuestion }}
                    </b-td>
                    <b-td class="text-center">
                      <div v-if="level_data.oldLevelId">v2</div>
                      <div v-else>v1</div>
                    </b-td>
                    <b-td class="text-center">
                      <div style="justify-content: center; display: flex;">
                        <b-form-checkbox :disabled="!permission_manage_topics && !permission_manage_topics_content" style="width: 0px" v-model="level_data.isActive" @change="changPublicAccess(level_data)" switch
                          size="lg">
                        </b-form-checkbox>
                      </div>
                    </b-td>
                    <b-td class="text-center" v-if="permission_manage_topics || permission_manage_topics_content">
                      <b-dropdown variant="link" no-caret>
                        <template #button-content>
                          <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>
                        <b-dropdown-item @click="
                          editleveldata(
                            level_data._id,
                            level_data.levelName,
                            level_data.noOfQuestion,
                            level_data.status
                          )
                          ">
                          <feather-icon height="20px" width="20px" icon="EditIcon" />
                          <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="
                          deleteleveldata(level_data._id)
                          ">
                          <feather-icon icon="TrashIcon" />
                          <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
    
                        <b-dropdown-item @click="
                          deleteV1Question(level_data._id)
                          ">
                          <feather-icon icon="TrashIcon" />
                          <span class="align-middle ml-50">Delete v1</span>
                        </b-dropdown-item>
    
                        <b-dropdown-item @click="
                          selectMigrateLevel(level_data)
                          ">
                          <feather-icon icon="CopyIcon" />
                          <span class="align-middle ml-50">Migrate to v2</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </b-td>
                  </b-tr>
                </template>
              </b-tbody>
            </b-table-simple>
          </b-overlay>
        </b-card>
        <b-modal id="migrate-v1-to-v2" no-close-on-backdrop hide-footer size="sm" @hidden="resetModal" centered
          title="Migrate to V2">
          <div class="modal_content">
            <b-alert :show="hasError" class="p-1" variant="danger">{{ MigrateMsg }}</b-alert>
            <b-alert :show="!hasError" class="p-1" variant="info">This Level will duplicte to New Question System</b-alert>
            <div v-if="!hasError" style="display: flex;
        justify-content: center;">
              <b-button :disabled="true" v-if="miragteLevel && miragteLevel.v2" variant="primary">Migrated
              </b-button>
              <b-button v-else @click="migrateLevelFunc" variant="primary">Migrate
              </b-button>
            </div>
          </div>
        </b-modal>
      </div>
    </div>

  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BOverlay,
  BTfoot,
  BAlert,
  BModal,
  BFormCheckbox,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { BBreadcrumb } from "bootstrap-vue";
import AddNewLevel from "./app/list/users-list/AddNewLevel.vue";
import AddEditLevel from "./app/list/users-list/AddEditLevel.vue";
import axios from "axios";
import Ripple from "vue-ripple-directive";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Helpers from "@/Helpers";

export default {
  components: {
    AddNewLevel,
    AddEditLevel,
    BOverlay,
    BBreadcrumb,
    BCard,
    BRow,
    ToastificationContent,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    vSelect,
    BModal,
    BAlert,
    BFormCheckbox,
    BSpinner,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      isAddNewLevelSidebarActive: false,
      isAddEditLevelSidebarActive: false,
      searchQuery: "",
      table_data: [],
      table_: [],
      editLevelStatus: "",
      show: false,
      levelidedit: "",
      Editlevel: "",
      EditLevelname: "",
      EditNoofquestion: "",
      AddSubcategoryid: "",
      deletelevel: { delete_id: "" },
      alertData: "",
      items: [],
      categoryId: "",
      miragteLevel: null,
      migrateQuestionTypes: [
        "single-select-two",
        "count-click",
        "multi-selectfour",
        "multi-select",
        "single-select",
        "multi-select-text-four",
        "multi-select-text",
        "correct-sentence",
        "correct-sentence-image",
        "count-click-sentences",
        "count-click-label",
        "count-click-sentences-text",
        "number-pattern-select",
        "missing-number",
        "word-problems-click",
        "least-greatest",
        "before-after-between",
        "unscramble-words-five",
        "unscramble-words-four",
        "unscramble-words-three",
        "lifecycle-image",
        "addsubtract-click",
        "before-word"
      ],
      MigrateMsg: null,
      bearerToken: null,
      
      permission_view_topics: false,
      permission_manage_topics: false,
      permission_view_topics_content: false,
      permission_manage_topics_content: false,
      Helpers,
      pageLoading: true,
    };
  },
  computed: {
    hasError() {
      return this.MigrateMsg != null;
    },
  },
  created() {
    this.bearerToken = "Bearer " + localStorage.getItem("_t");
    this.gettabledata();
  },
  methods: {
    async changPublicAccess(level) {
      try {
        level.status = level.isActive ? 'active' : 'inactive';
        let levelObj = {
          _id: level._id,
          status: level.status,
        }
        const response = await axios.put(`${process.env.VUE_APP_API_URL}/level/status`, levelObj, {
          headers: {
            Authorization: this.bearerToken,
          },
        });
        if (response.data.status == 200) {
          this.gettabledata();
          this.alertData = response.data.message;
          this.showToast('success');
        } else {
          this.alertData = response.data.message;
          this.showToast('danger');
        }
      } catch (error) {
        console.error('Error saving image to gallery', error);
      }
    },
    async deleteV1Question(id) {
      try {
        Swal.fire({
          title: "Are you sure?",
          text: "You want to delete this level!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            const response = await axios.delete(`${process.env.VUE_APP_API_URL}/v1/level/${id}`, {
              headers: {
                Authorization: this.bearerToken,
              },
            });
            if (response.data.status == 200) {
              this.gettabledata();
              this.alertData = response.data.message;
              this.showToast('success');
            } else {
              this.alertData = response.data.message;
              this.showToast('danger');
            }
          }
        });

      } catch (error) {
        console.error('Error saving image to gallery', error);
      }
    },
    async migrateLevelFunc() {
      try {
        let levelObj = {
          levelId: this.miragteLevel._id,
        }
        const response = await axios.post(process.env.VUE_APP_API_URL + '/v2/questions/' + this.miragteLevel.question.questionType, levelObj, {
          headers: {
            Authorization: this.bearerToken,
          },
        });
        if (response.data) {
          this.alertData = 'V1 Migrated to V2 successfully.';
          this.showToast('success');
          this.gettabledata();
          this.$root.$emit("bv::hide::modal", "migrate-v1-to-v2");
        }
      } catch (error) {
        console.error('Error saving image to gallery', error);
      }
    },
    resetModal() {
      this.MigrateMsg = null;
    },
    selectMigrateLevel(level) {
      const questionTypeExists = this.migrateQuestionTypes.includes(level.question.questionType);
      if (!questionTypeExists) {
        this.MigrateMsg = `The following questions type are not supported for migration. ${level.question.questionType}`;
      }
      this.miragteLevel = level;
      this.$root.$emit("bv::show::modal", "migrate-v1-to-v2");
    },
    showToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: this.alertData,
          variant,
        },
      });
    },
    levelsFilter() {
      this.show = true;
      setTimeout(() => {
        if (this.searchQuery.trim().length > 0) {
          let finaldata = this.table_.filter((item) => {
            if (
              item.levelName &&
              item.question.questionType &&
              item.question.title
            ) {
              return this.searchQuery
                .toLowerCase()
                .split(" ")
                .every(
                  (v) =>
                    item.levelName
                      .toString()
                      .toLowerCase()
                      .includes(v) ||
                    item.question.title
                      .toLowerCase()
                      .includes(v) ||
                    item.question.questionType
                      .toLowerCase()
                      .includes(v)
                );
            }
          });
          this.table_data = finaldata;
          this.show = false;
        } else {
          this.table_data = this.table_;
          this.show = false;
        }
      }, 1000);
    },
    gettabledata: function () {
      axios
        .get(
          process.env.VUE_APP_API_URL +
          `/category-levels/${this.$route.params.subcatId}`,
          {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((get_response) => {
          this.table_ = get_response.data.level;
          this.table_data = get_response.data.level;
          this.categoryId = get_response.data.categoryData._id;
          this.items = get_response.data.manage_topics || get_response.data.manage_topics_content ? [
            {
              text: get_response.data.classData.className,
              to: {
                path: `/topics?class=${get_response.data.classData._id}`,
              },
            },
            {
              text: get_response.data.subjectData.subjectName,
              to: {
                path: `/topics?class=${get_response.data.classData._id}&subject=${get_response.data.subjectData._id}`,
              },
            },
            {
              text: get_response.data.categoryData.categoryName,
              to: {
                path: `/topics?class=${get_response.data.classData._id}&subject=${get_response.data.subjectData._id}&category=${get_response.data.categoryData._id}`,
              },
            },
            {
              text: "Levels",
              active: true,
            },
          ]:[
            {
              text: get_response.data.classData.className,
              active: true,
            },
            {
              text: get_response.data.subjectData.subjectName,
              active: true,
            },
            {
              text: get_response.data.categoryData.categoryName,
              active: true,
            },
            {
              text: "Levels",
              active: true,
            },
          ];
          this.permission_view_topics = get_response.data.view_topics;
          this.permission_manage_topics = get_response.data.manage_topics;
          this.permission_view_topics_content = get_response.data.view_topics_content;
          this.permission_manage_topics_content = get_response.data.manage_topics_content;
          this.pageLoading = false;
        })
        .catch(function (error) {
          this.pageLoading = false;
          this.alertData = "Try again, something went wrong !";
          this.showToast("danger");
        });
    },
    clickaddnewlevel: function (add_level_data) {
      axios
        .post(
          process.env.VUE_APP_API_URL + "/category/level/add",
          add_level_data,
          {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          this.alertData = "Level Successfully Added";
          this.showToast("success");
          this.gettabledata();
        })
        .catch(function (error) {
          this.alertData = "Try Again Level Not Added";
          this.showToast("danger");
        });
    },

    clickaddeditlevel: function (add_level_data) {
      axios
        .post(
          process.env.VUE_APP_API_URL + "/category/level/edit",
          add_level_data,
          {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          this.alertData = "Level Successfully Edit";
          this.showToast("success");
          this.gettabledata();
        })
        .catch(function (error) {
          this.alertData = "Try Again Level Not Edit";
          this.showToast("danger");
        });
    },
    deleteleveldata: function (add_level_data) {
      Swal.fire({
        title: "Are you sure?",
        text: "Do You want to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deletelevel.delete_id = add_level_data;
          axios
            .post(
              process.env.VUE_APP_API_URL + "/delete-level",
              this.deletelevel,
              {
                headers: {
                  Authorization:
                    "Bearer " + localStorage.getItem("_t"),
                },
              }
            )
            .then((response) => {
              if (response.data == "Level not delete") {
                this.alertData =
                  response.data +
                  ".Please delete all the question first";
              } else {
                this.alertData = "Level deleted";
              }
              this.showToast("danger");
              this.gettabledata();
            });
        }
      });
    },
    editleveldata(levelid, levelname, noofquestioninlevel, levelStatus) {
      this.isAddEditLevelSidebarActive = true;
      this.levelidedit = levelid;
      this.EditLevelname = levelname.toString();
      this.EditNoofquestion = noofquestioninlevel.toString();
      if (levelStatus) {
        this.editLevelStatus = levelStatus;
      } else {
        this.editLevelStatus = "";
      }
    },
    addnewlevelinsubcategory() {
      this.isAddNewLevelSidebarActive = true;
      this.AddSubcategoryid = this.$route.params.subcatId;
    },
    savedatlevel(x, y) {
      localStorage.removeItem("feedbackQuestionId");
      let a = JSON.stringify(x);
      localStorage.setItem("level", a);
      let d = JSON.stringify(y);
      localStorage.setItem("categoryId", d);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
